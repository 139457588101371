/** @jsx jsx */
import { css } from "@emotion/react";

export const footer1 = css`
  position: relative;
  padding: 0;
  background-color: #FD3E67;
`;

export const copyright = css`
  padding: 40px 0px;
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #FFFFFF;
    margin-top: 32px;
  }
`;
