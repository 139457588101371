/** @jsx jsx */
import { jsx } from "@emotion/react";
import LogoSerios from '../assets/images/lp/logotipo_serios.svg'
import {
  footer1,
  copyright,
} from "../assets/styles/Footer.styles";

const Footer = () => {
  return (
    <footer css={footer1}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-sm-12 text-center">
            <div css={copyright}>
              <div><img src={LogoSerios} /></div>
              <p>Av. T-3, 2352 - St. Bueno Goiânia - GO, 74210-240 62 3996-7050</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
